<template>
  <div>
    <div class="q-ma-md">
      <q-list>
        <q-item-label header>排行榜[TOP20]</q-item-label>
        <q-scroll-area style="height: 630px;">
          <q-item class="q-mb-sm"
                  v-for="(u,i) in rankPlayer" :key="u.steamId" clickable v-ripple>

            <q-item-section>
              <q-item-label caption lines="1" style="font-size: 18px">
                {{ i + 1 }}
                <q-icon name="star" color="yellow" style="margin-left: 10px"/>
                <q-avatar rounded size="35px" style="margin-right: 10px">
                  <img :src="u.avatarFull">
                </q-avatar>
                {{ u.personaName }}
              </q-item-label>
            </q-item-section>

            <q-item-section>
              <q-item-label caption lines="1">
                游戏总时长:{{ null == u.playTime ? 0 : Math.round(u.playTime / 60) + '小时' }}
              </q-item-label>
            </q-item-section>

            <q-item-section>
              <q-item-label caption lines="1">
                对局场次:{{ u.isRank }}
              </q-item-label>
            </q-item-section>

            <q-item-section>
              <q-img v-if="null != u && null != u.level && u.isRank >=7"
                     :src="u.level.levelImage"
                     spinner-color="white"
                     fit="fill"
                     style="height: 45px; max-width: 40px"
              />
            </q-item-section>

            <q-item-section>
              <q-item-label caption lines="1">
                {{u.level.levelName}}
              </q-item-label>
            </q-item-section>

            <q-tooltip transition-show="scale"
                       transition-hide="scale" anchor="center right" self="center right">
              {{ u.personaName }}
            </q-tooltip>
          </q-item>
        </q-scroll-area>
      </q-list>
    </div>
  </div>
</template>

<script>
import {ranks} from "@/api/player";

export default {
  name: "Rank",
  data() {
    return {
      rankPlayer: []
    }
  },
  created() {
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      ranks().then((res) => {
        this.rankPlayer = res.data.extend;
      })
    }
  }
}
</script>

<style scoped>

</style>
